<template>
	<div id="recruit">
		<banner :picS="bannerS"></banner>
		<div class="recruitment">
			<titleOne id="recruit_1" en='recruitment' title="企业招聘"></titleOne>
			<div class="widthBox">
				<recruitBox />
			</div>
		</div>
	</div>
</template>

<script>
	import titleOne from '@/components/titleOne.vue'
	import banner from '@/components/banner.vue'
	import recruitBox from '@/components/recruit.vue'
	export default {
		name: 'recruit',
		components:{
			titleOne,
			banner,
			recruitBox
		},
		computed: {
			bannerS() {
				return this.$store.state.bannerS
			},
		},
		data:function() {
			return {
			};
		},
		created() {
		},
		mounted() {
			this.$nextTick(function(){
				if(this.$route.query.to != undefined || this.$route.query.to != null){
					setTimeout(()=>{
						document.getElementById(this.$route.query.to).scrollIntoView()
					},500)
				}else{
					document.body.scrollIntoView()
				}
			})
		},
		methods:{
		}
	}
</script>

<style lang="scss">
	$paddingTop:60px;
	#recruit{
		.recruitment{
			padding-top:$paddingTop;
		}
	}
</style>
