<template>
	<div id="recruitBox">
		<ul>
			<li class="item" v-for="(item,index) in resume">
				<div class="left">
					<h4>{{item.position}}</h4>
					<div class="price">
						<span v-for="it in item.treatment">
							{{it}}
						</span>
					</div>
					<div class="label">
						<span v-for="it in item.welfare_label">{{it}}</span>
					</div>
					<div @click="openBox" class="subBtn" :data-email="item.email">
						简历投递
						<img class="icon" src="@/assets/icon_04.png" alt="">
					</div>
				</div>
				<div class="right">
					<h4>岗位职责：</h4>
					<div class="info" v-html="item.duty">
					</div>
					<h4>岗位要求：</h4>
					<div class="cont" v-html="item.requirement">
					</div>
				</div>
			</li>
		</ul>
		<el-pagination v-if="total>10" :page-size="pageSize" :current-page="page" @current-change="joinChange" layout="prev, pager, next" :total="total"></el-pagination>
	</div>
</template>

<script>
	export default {
		data:function() {
			return {
				page:1,
				pageSize:10,
				resume:[],
				total:0,
			};
		},
		created() {
			this.getJoin()
		},
		mounted() {
		},
		methods:{
			getJoin(){
				this.$api.post('home/index/enterpriseRecruitment',{
					page:this.page,
					pageSize:this.pageSize
				}).then(res=>{
					this.resume = res.data.enterprise_recruitment
					this.total = res.data.total
				})
			},
			joinChange(e){
				this.page = e
				this.getJoin()
			},
			openBox(e){
				var email = e.currentTarget.dataset.email
				this.$alert('请投递到此邮箱:'+email, '简历投递', {
					confirmButtonText: '确定',
					customClass:'joinMessage',
					callback: action => {
					}
				});
			}
		}
	}
</script>

<style lang="scss">
	$paddingTop:60px;
	.joinMessage{
		.el-message-box__header{
			span{
				font-size:24px;
			}
		}
		.el-message-box__message{
			p{
				font-size:18px;
			}
		}
	}
	#recruitBox{
		.el-pagination{
			margin:50px auto 0;
			text-align: center;
			button{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-weight: 500;
				background-color: rgba($color: #1D2A55, $alpha:1);
				color: #FFF;
				i{
					font-size:24px;
				}
				&:disabled {
				    color: #1D2A55;
				    background-color: rgba($color: #1D2A55, $alpha:0.1);
				    cursor: not-allowed;
				}
			}
			li{
				line-height:40px;
				height:40px;
				padding:0 8px;
				font-size:24px;
				margin:0 5px;
				font-weight: 500;
				color:rgba($color: #1D2A55, $alpha: 0.4);
				&.active{
					color:rgba($color: #1D2A55, $alpha: 1);
				}
			}
		}
	
		ul{
			.item{
				margin-top:50px;
				display: block;
				width:100%;
				// height:560px;
				box-sizing: border-box;
				background:url(../assets/bg_04.png) no-repeat;
				background-size:100% 100%;
				color:#1D2A55;
				padding:60px;
				transition: 0.3s;
				&.active,&:hover{
					color:#fff;
					background:url(../assets/bg_03.png) no-repeat;
					background-size:100% 100%;
					.left{
						.label{
							span{background:#FFF;color:#1D2A55}
						}
						.subBtn{
							background:none;
							border:1px solid #FFF;
						}
						&::after{
							background:#FFF;
						}
					}
					.right{
						.info{
							border-color:#FFF;
						}
						p{
							color:#FFF !important;
							span{
								color:#FFF !important;
							}
						}
					}
				}
				&::after{
					content:'';display: block;clear: both;width:0;height:0;overflow: hidden;
				}
				.left{
					width:380px;
					float:left;
					position: relative;
					&::after{
						content:'';width:1px;height:300px;
						position: absolute;top:50%;
						right:0;
						transform: translate(0,-50%);
						background:#1D2A55;
					}
					h4{
						font-size:36px;
					}
					.price{
						margin-top:40px;
						span{
							line-height:40px;font-size:20px;
							display: block;
						}
					}
					.label{
						margin-top:50px;
						overflow: hidden;
						span{
							border:1px solid rgba($color: #1D2A55, $alpha: 0.7);
							text-align: center;
							font-size:16px;
							padding:0px 14px;
							line-height:32px;
							display: inline-block;
							border-radius:30px;
							white-space: nowrap;
							margin-right:10px;
							margin-top:10px;
						}
					}
					.subBtn{
						display:inline-block;
						width:160px;
						height:50px;
						background:#1D2A55;
						color:#FFF;
						text-align: center;
						line-height:50px;
						font-size:18px;
						margin-top:100px;
						position: relative;
						cursor: pointer;
						.icon{
							position: absolute;
							top:50%;
							right:-54px;
							width:77px;
							transform: translate(0,-50%);
						}
					}
				}
				.right{
					margin-left:500px;
					h4{
						font-size:24px;
					}
					.info{
						margin-top:20px;
						border-bottom:1px solid #1D2A55;
						padding-bottom:15px;
						margin-bottom:20px;
						
					}
					p{
						font-size:18px !important;
						line-height:28px !important;
						color:#1D2A55 !important;
						span{
							font-size:18px !important;
							line-height:28px !important;
							color:#1D2A55 !important;
						}
					}
					.cont{
						margin-top:20px;
					}
				}
			}
		}
	}
	
	@media screen and(max-width:1400px) {
		#recruitBox{
				ul{
					.item{
						// height:500px;
						.left{
							width:420px;
							h4{
								font-size:32px;
							}
							.price{
								margin-top:30px;
								span{
									font-size:18px;
								}
							}
							.label{
								margin-top:40px;
								span{
									font-size:14px;
								}
							}
						}
						.right{
							h4{
								font-size:20px;
							}
							p{
								font-size: 16px !important;
								span{
									font-size: 16px !important;
								}
							}
						}
					}
				}
			}
	}
	@media screen and(max-width:1200px) {
		#recruitBox{
				ul{
					.item{
						// height:500px;
						.left{
							width:400px;
							h4{
								font-size:28px;
							}
							.price{
								margin-top:30px;
								span{
									font-size:16px;
								}
							}
							.label{
								margin-top:40px;
								span{
									font-size:14px;
								}
							}
						}
						.right{
							margin-left:450px;
							h4{
								font-size:20px;
							}
							p{
								font-size: 14px !important;
								span{
									font-size: 14px !important;
								}
							}
						}
					}
				}
			}
	}
</style>
